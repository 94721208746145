@import 'mixins';

*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.site-footer{
    width: 100%;
    // height: 300px;
    padding: 50px 0px 50px 0px;
    background-color: lighten($black, 10%);
    border-top: 1px solid $light-gray;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    // background-color: red;

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        // @include flexRow(space-between);
        @include flexColumn(flex-start);
    }

    &__top-line{
        width: 100%;
        // height: 300px;
        // background-color: pink;
        @include flexRow(space-between);
    }

    &__menu{
        width: 200px;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
        list-style: none;
        max-height: 300px;
        overflow-y: auto;

        &::-webkit-scrollbar{
            width: 3px;
        }
        
        &::-webkit-scrollbar-thumb{
            background-color: $accent;
        }
    }

    &__menu-header{
        color: white;
        font-size: 20px;
        margin-bottom: 20px;
    }

    &__menu-item{
        // background-color: orange;
        width: 100%;
        margin: 5px 0px 5px 0px;
        font-size: 14px;
        @include flexRow(center);
    }
    
    &__menu-link{
        // background-color: aqua;
        text-decoration: none;
        color: $light-gray;
        transition: 0.3s;
        width: 100%;
        &:hover{
            color: white;
        }
    }

    &__contacts-block{
        @include flexColumn(flex-start);
        // background-color: plum;
        width: 320px;
        height: 100%;
    }

    &__subscribe-button{
        // width: 300px;
        // height: 45px;
        padding: 15px 45px 15px 15px;
        background-color: rgb(73, 73, 73);
        border-radius: 3px;
        text-decoration: none;
        color: white;
        font-size: 13px;
        margin-bottom: 35px;
        position: relative;
        cursor: pointer;
        svg{
            position: absolute;
            right: 18px;
            path{
                fill: white;
            }
        }
        background-position: center;
        transition: background 0.8s;

        &:hover {
            background: $accent radial-gradient(circle, transparent 1%, $accent  1%) center/15000%;
        }

        &:active {
            background-color: lighten($accent, 10%);
            background-size: 100%;
            transition: background 0s;
        }
    }

    &__call-button{
        margin-left: 30px;
        color: #999;
        text-decoration: none;
        cursor: pointer;
        font-size: 14px;
        transition: 0.3s;
        &:hover{
            color: white;
        }
    }

    &__contact-link{
        color: white;
        text-decoration: none;
        // margin-left: 21px;
        margin-top: 15px;
        cursor: pointer;
        transition: 0.3s;
        svg{
            margin-right: 15px;
            path{
                fill: $light-gray;
            }
        }
        &:hover{
            color: $light-gray;
        }
    }

    &__contact{
        color: white;
        margin-top: 15px;
        // margin-left: 21px;
        svg{
            margin-right: 15px;
            path{
                fill: $light-gray;
            }
        }
    }

    &__middle-line{
        margin-top: 30px;
        width: 100%;
        height: 45px;
        // background-color: pink;
        @include flexRow(center);
        position: relative;
        align-items: center;
        &::before{
            width: 100%;
            height: 1px;
            position: absolute;
            display: block;
            content: "";
            background-color: $light-gray;
        }
    }

    &__soc-container{
        width: 120px;
        height: 45px;
        // background-color: plum;
        background-color: lighten($black, 10%);
        z-index: 1;
        @include flexRow(center);
    }

    &__soc-button{
        width: 40px;
        height: 40px;
        // background-color: orange;
        border: 1px solid $light-gray;
        margin: 0px 5px 0px 5px;
        @include flexRow(center);
        align-items: center;

        svg{
            width: 15px;
            height: 15px;
            path{
                fill: $light-gray;
            }
        }

        &:hover{
            background-color: lighten($accent, 5%);
            border-color: white;
            svg{
                path{
                    fill: white;
                }
            }
        }
        // width: 120px;
    }

    &__bottom-line{
        width: 100%;
        height: 40px;
        // background-color: pink;
        margin-top: 50px;
        @include flexRow(space-between);
        align-items: center;
    }

    &__rights-text{
        color: $light-gray;
        font-size: 14px;
    }

    &__pay-types{
        height: 40px;
        // background-color: plum;
        @include flexRow(center);
    }

    &__pay-type{
        width: 40px;
        height: 40px;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
        margin: 0px 10px 0px 10px;
    }

    &__pay-img{
        max-width: 40px;
        max-height: 40px;
    }

    &__work-text{
        color: $light-gray;
        font-size: 14px;
    }

    &__work-text_accent{
        color: $light-gray;
        font-size: 16px;
        color: white;
        letter-spacing: 5px;
        margin-left: 5px;
    }
}

@media (max-width: 1360px){
    .site-footer{
        // display: none;

        &__container{
            // background-color: yellow;
        }

        &__top-line{
            // background-color: pink;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__menu{
            // background-color: plum;
            margin: 10px;
        }
        
        &__contacts-block{
            width: 300px;
            margin: 10px;
            // background-color: plum;
        }

        &__call-button{
            text-align: center;
            margin-left: 0px;
        }

        &__contact-link{
            @include flexRow(center);
        }

        &__contact{
            @include flexRow(center);
            text-align: center;
        }

        &__bottom-line{
            height: unset;
            flex-wrap: wrap;
            // background-color: pink;
        }

        &__rights-text{
            width: 100%;
            text-align: center;
        }

        &__pay-types{
            width: 100%;
            @include flexRow(center);
            margin: 10px 0px 10px 0px;
        }

        &__work-text{
            width: 100%;
            text-align: center;
        }
    }
}
